import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Markdown from "../components/markdown"
import PageTitle from "../components/page-title"
import ShareButtons from "../components/share-buttons"
import * as styles from "../styles/templates/TbPost.module.css"

const Template = ({ data, location }) => {
  const { node } = data
  const { title, post_areas } = node
  return (
    <Layout
      title={title}
      description={node.meta_description}
      keywords={node.meta_keywords}
      image={node.ogp_image?.publicURL}
      breadcrumbs={buildBreadcrumbs(node)}
    >
      <PageTitle>{title}</PageTitle>
      <div className="pc:mb-8 tablet_sp:mb-6 flex flex-row justify-between items-center">
        <div>
          <time dateTime={node.dateStandard}>{node.date}</time>
        </div>
        <ShareButtons url={location.href} title={title} size={36} />
      </div>
      <section className="pc:mb-8 tablet_sp:mb-6">
        <Markdown>{node.body ?? ``}</Markdown>
      </section>
      {(post_areas.length > 0) ? (
        <div className="flex flex-row flex-wrap pc:mb-16 tablet_sp:mb-8">
          {post_areas.map((area) => {
            return (
              <Link key={area.path} to={area.path} className={styles.postAreaItem}>{area.name}</Link>
            )
          })}
        </div>
      ) : ``}
    </Layout>
  )
}

/**
 * パンくずリストを生成する
 */
const buildBreadcrumbs = (post) => {
  if (post?.post_area_main) {
    const area = post.post_area_main

    if (area?.path && area?.name) {
      return [
        { label: area.name ?? ``, href: area.path },
        { label: post.title, href: null },
      ]
    }
  }

  // Strapi の制限で `post_area_main` は必須にできないので
  // `post_area_main` が空の場合はフォールバックする
  return [
    { label: post.title, href: null },
  ]
}

export default Template

export const pageQuery = graphql`
  query($path: String!) {
    node: tbPost(path: { eq: $path }) {
      title
      ogp_image {
        publicURL
      }
      meta_description
      meta_keywords
      file {
        publicURL
      }
      date(formatString: "YYYY.MM.DD")
      dateStandard: date(formatString: "YYYY-MM-DD")
      post_area_main {
        name
        path
      }
      post_areas {
        name
        path
      }
      body
    }
  }
`
